import React from "react";
import Image from "next/image";
import classNames from "classnames";

export const Logo: React.FC<{
  className: string;
  type: "white" | "dark";
}> = ({ className, type = "dark" }) => {
  return (
    <Image
      className={className}
      src={type === "white" ? "/logo-white.jpg" : "/logo-bilog.svg"}
      alt="Logo Bilog"
      width={50}
      height={50}
    />
  );
};
