import classNames from "classnames";

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames(
        "animate-skeleton bg-gradient-to-r from-slate-100 via-slate-200 to-slate-100 bg-[length:200%_100%] rounded-md bg-slate-200",
        className
      )}
      {...props}
    />
  );
}

export { Skeleton };
