"use client";

import * as React from "react";
import clxs from "classnames";
import { DayPicker } from "react-day-picker";
import { es } from "date-fns/locale/es";
import { Locale } from "date-fns";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import "react-day-picker/style.css";
import { RangeCalendarPropsT } from "@/types/components/calendar";

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  type: string;
};

export const RangeCalendar: React.FC<RangeCalendarPropsT> = ({
  type = "principal",
  month,
  className,
  classNames,
  showOutsideDays = true,
  disabledPastDates = false,
  min,
  ...props
}) => {
  const [currentMonth, setCurrentMonth] = React.useState(month);
  React.useMemo(() => {
    setCurrentMonth(month);
  }, [month]);
  const validateClassName = ["compare"].includes(type);

  return (
    <DayPicker
      key={currentMonth?.toISOString()}
      month={currentMonth}
      disabled={
        disabledPastDates
          ? (date) => date > new TZDate(new Date().toISOString(), TIMEZONES.AR)
          : false
      }
      locale={es as unknown as Locale}
      onMonthChange={(newMonth) => setCurrentMonth(newMonth)}
      showOutsideDays={showOutsideDays}
      className={className}
      classNames={{
        months: "flex flex-row gap-12 justify-between",
        month: "capitalize space-y-4",
        caption: "flex justify-center pt-1 relative items-center ml-4",
        caption_label: "text-sm font-medium items-center flex",
        cell: clxs(
          "size-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20 dark:[&:has([aria-selected].day-outside)]:bg-blue-800/50 dark:[&:has([aria-selected])]:bg-blue-800",
          validateClassName
            ? "[&:has([aria-selected].day-outside)]:bg-green-100/50 [&:has([aria-selected])]:bg-green-200"
            : "[&:has([aria-selected].day-outside)]:bg-blue-100/50 [&:has([aria-selected])]:bg-blue-200"
        ),
        day: clxs(
          "size-9 p-0 rounded-md font-normal aria-selected:opacity-200 hover:!bg-blue-500 hover:!text-blue-50 border-none !text-slate-900",
          {
            "hover:!bg-green-300 hover:!text-green-50": validateClassName,
          }
        ),
        disabled: "text-slate-500 opacity-50 dark:text-slate-400",
        range_end: "bg-blue-500",
        range_start: "bg-blue-500",
        selected: clxs(
          "bg-blue-600 hover:bg-blue-600 hover:!text-blue-500 focus:bg-blue-600 focus:text-blue-50 dark:bg-blue-50 dark:text-blue-900 dark:hover:bg-blue-50 dark:hover:text-blue-900 dark:focus:bg-blue-50 dark:focus:text-blue-900",
          {
            "bg-green-600 text-green-50 hover:bg-green-600 hover:text-green-50 focus:bg-green-600 focus:text-green-50 dark:bg-green-50 dark:text-green-900 dark:hover:bg-green-50 dark:hover:text-green-900 dark:focus:bg-green-50 dark:focus:text-green-900":
              validateClassName,
          }
        ),
        today: clxs(
          "dark:bg-blue-800 dark:text-blue-50 !text-white",
          validateClassName ? "bg-green-300" : "bg-blue-300"
        ),
        day_outside:
          "day-outside text-slate-500 opacity-50 aria-selected:bg-slate-100/50 aria-selected:text-slate-500 aria-selected:opacity-30 dark:text-slate-400 dark:aria-selected:bg-slate-800/50 dark:aria-selected:text-slate-400",
        range_middle: clxs(
          "aria-selected:bg-blue-200 !rounded-none aria-selected:text-blue-900 dark:aria-selected:bg-blue-800 dark:aria-selected:text-blue-50",
          {
            "aria-selected:bg-green-200 aria-selected:text-green-900 dark:aria-selected:bg-green-800 dark:aria-selected:text-green-50":
              validateClassName,
          }
        ),
        hidden: "invisible",
        ...classNames,
      }}
      {...props}
    />
  );
};
RangeCalendar.displayName = "RangeCalendar";
