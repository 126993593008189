"use client";

import * as React from "react";
import { PieSectorDataItem } from "recharts/types/polar/Pie";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  ReferenceLine,
  Legend,
  Brush,
  Line,
  Pie,
  PieChart,
  Cell,
  Sector,
  LineChart,
  Label,
} from "recharts";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/atoms";
import { FormattedTooltip } from "@/components/molecules/tooltips";

export const Bars = ({
  data,
  height,
  Xkey,
  BarKey,
  grid = false,
  color = "currentColor",
  tickFormatter,
}: {
  data: any[];
  height: number;
  color?: string;
  Xkey: string;
  BarKey: string;
  grid?: boolean;
  tickFormatter?: (value: number) => string;
}) => {
  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={data}>
        <XAxis
          dataKey={Xkey}
          stroke="#888888"
          fontSize={12}
          tickLine={false}
          axisLine={false}
        />
        {grid && <CartesianGrid strokeDasharray="3 3" />}
        <Tooltip />
        <YAxis
          stroke="#888888"
          fontSize={12}
          tickLine={false}
          axisLine={false}
          tickFormatter={tickFormatter}
        />
        <Bar dataKey={BarKey} fill={color} radius={[4, 4, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};
Bars.displayName = "Bars";

export const BarOrLineChart: React.FC<{
  data: any;
  color: { original: string; comparison: string };
  compare: string;
  height: number;
}> = ({ data, color, compare, height }) => {
  const typeGraphic = data.chart_type;
  const BarOrLineComponent: any = typeGraphic === "line" ? Line : Bar;
  const formatDateGraphic = (dateString: string) => {
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const date = new TZDate(new Date(dateString).toISOString(), TIMEZONES.AR);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return ` ${day.toString().padStart(2, "0")}-${month}-${year} `;
  };

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ComposedChart
        layout={typeGraphic !== "horizontalBar" ? "horizontal" : "vertical"}
        width={500}
        height={300}
        data={data?.chart_data || []}
        margin={{
          top: 25,
          right: 60,
          left: typeGraphic === "horizontalBar" ? 20 : 0,
          bottom: 2,
        }}
      >
        <Tooltip
          content={
            <FormattedTooltip
              type={typeGraphic}
              color={color}
              name={data.title}
              compare={compare}
            />
          }
        />
        <CartesianGrid />
        {typeGraphic !== "horizontalBar" ? (
          <>
            <XAxis
              dataKey={typeGraphic === "line" ? "period" : "smallLabel"}
              tick={{
                fontSize: 11,
              }}
              domain={["dataMin", "dataMax"]}
              tickFormatter={
                typeGraphic === "line" ? formatDateGraphic : () => ""
              }
              interval={typeGraphic === "line" ? undefined : 0}
              textAnchor="end"
            />
            <YAxis
              tick={{
                fontSize: 12,
              }}
            />
          </>
        ) : (
          <>
            <XAxis
              type="number"
              tick={{
                fontSize: 12,
              }}
            />
            <YAxis
              dataKey="label"
              type="category"
              tick={{
                fontSize: 12,
              }}
            />
          </>
        )}
        <Tooltip />
        {compare && <Legend iconType="circle" iconSize={10} />}
        <ReferenceLine y={0} stroke="#000" />
        {typeGraphic === "line" && (
          <Brush
            dataKey="period"
            height={30}
            stroke="#333333"
            tickFormatter={formatDateGraphic}
          >
            <ComposedChart data={data}>
              <BarOrLineComponent
                type="monotone"
                dataKey="value"
                fill={color.original}
                stroke={color.original}
              />
              {compare && (
                <BarOrLineComponent
                  type="monotone"
                  dataKey="comparation_value"
                  stroke={color.comparison}
                  fill={color.comparison}
                />
              )}
            </ComposedChart>
          </Brush>
        )}
        <BarOrLineComponent
          name="Principal"
          type="monotone"
          dataKey="value"
          fill={color.original}
          stroke={color.original}
        />
        {compare && (
          <BarOrLineComponent
            name="Comparación"
            type="monotone"
            dataKey="comparation_value"
            stroke={color.comparison}
            fill={color.comparison}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export const ChartPie = ({
  values,
  dataKey,
  device,
}: {
  values: any;
  dataKey: string;
  device: string;
}) => {
  const COLORS = [
    "#C4B5FD",
    "#FCD34D",
    "#F9A8D4",
    "#93C5FD",
    "#86EFAC",
    "#8B5CF6",
    "#F59E0B",
    "#EC4899",
    "#3B82F6",
    "#64748B",
  ];
  const id = "pie-interactive";
  const mobile = ["mobile"].includes(device);
  const [activeData, setActiveData] = React.useState(values[0].name);

  const activeIndex = React.useMemo(
    () =>
      values.findIndex((item: any) =>
        [item.name, item.period].includes(activeData)
      ),
    [activeData, dataKey]
  );

  return (
    <>
      <Select
        value={activeData}
        onValueChange={setActiveData}
        defaultValue={values?.[0]?.name || values?.[0]?.period}
      >
        <SelectTrigger
          className="mt-4 ml-auto h-7  rounded-lg pl-2.5"
          aria-label="Select a value"
        >
          <SelectValue placeholder="Selec data" />
        </SelectTrigger>
        <SelectContent className="rounded-xl">
          {values
            ?.filter((key: any) => key.quantity !== 0)
            ?.map((key: any, index: number) => {
              if (!values) {
                return null;
              }
              return (
                <SelectItem
                  key={key.id}
                  value={key?.name || key?.period}
                  className="rounded-lg [&_span]:flex"
                >
                  <div className="flex items-center gap-2 text-xs">
                    <span
                      className="flex h-3 w-3 shrink-0 rounded-sm"
                      style={{
                        backgroundColor: `${COLORS[index % COLORS.length]}`,
                      }}
                    />
                    {key?.name || key?.period}
                  </div>
                </SelectItem>
              );
            })}
        </SelectContent>
      </Select>
      <ChartContainer
        id={id}
        config={values}
        className="mx-auto aspect-square w-full min-h-[300px] max-h-[400px]"
      >
        <PieChart>
          <ChartTooltip
            cursor={false}
            active
            defaultIndex={0}
            content={
              <ChartTooltipContent
                hideIndicator
                labelFormatter={(_: any, payload: any) => {
                  if (payload && payload.length) {
                    const { name, quantity, period } = payload[0]?.payload;
                    const totalQuantity = values.reduce(
                      (acc: number, item: any) => acc + item.quantity,
                      0
                    );
                    const percentage =
                      quantity > 0
                        ? ((quantity / totalQuantity) * 100).toFixed(2)
                        : 0;
                    return `(${name || period} ${percentage}%)`;
                  }
                  return null;
                }}
              />
            }
          />
          <Pie
            data={values}
            cx="50%"
            cy="50%"
            labelLine={false}
            dataKey={dataKey}
            innerRadius={mobile ? 60 : 70}
            outerRadius={mobile ? 100 : 120}
            strokeWidth={5}
            activeIndex={activeIndex}
            activeShape={({ outerRadius = 0, ...props }: PieSectorDataItem) => (
              <g>
                <Sector {...props} outerRadius={outerRadius + 10} />
                <Sector
                  {...props}
                  outerRadius={outerRadius + 25}
                  innerRadius={outerRadius + 12}
                />
              </g>
            )}
            onMouseEnter={(_: any, index: number) => {
              setActiveData(values[index].name || values[index].period);
            }}
          >
            <Label
              content={({ viewBox }) => {
                if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                  const name = values[activeIndex]?.name;
                  if (!name) return null;

                  return (
                    <text
                      x={viewBox.cx}
                      y={viewBox.cy}
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      <tspan
                        x={viewBox.cx}
                        y={viewBox.cy}
                        className="font-nunito text-xs fill-current"
                      >
                        {name?.length > 12 ? name.slice(0, 12) + "..." : name}
                      </tspan>
                    </text>
                  );
                }
              }}
            />
            {values?.map((_: any, index: number) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ChartContainer>
    </>
  );
};

export const LineCharts = ({
  values,
  CompareKey,
  BarKey,
  Xkey,
  compare,
  height,
  legend,
  legendCompare,
}: any) => {
  const customLegendFormatter = (_value: string, entry: any) =>
    entry.payload.label;

  const customTooltipFormatter = ({ event, legend, legendCompare }: any) => {
    const { active, payload, label } = event;

    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
          }}
        >
          <p className="bg-slate-100 px-2 py-1">{label}</p>
          <p className={`px-2 py-1 flex items-center gap-x-2`}>
            <div
              className="rounded-full size-3"
              style={{ background: payload[0]?.color }}
            />

            {`${legend}: ${payload[0]?.value}`}
          </p>
          {payload[1] && (
            <p className="px-2 py-1 flex items-center gap-x-2">
              <div
                className="rounded-full size-3"
                style={{ background: payload[1]?.color }}
              />
              {`${legendCompare}: ${payload[1]?.value}`}
            </p>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart
        width={300}
        height={300}
        data={values}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={Xkey}
          tick={{
            fontSize: 12,
          }}
        />
        <YAxis
          tick={{
            fontSize: 12,
          }}
        />
        <Tooltip
          content={(e) => {
            const values = {
              event: e,
              legend: legend,
              legendCompare: legendCompare,
            };
            return customTooltipFormatter(values);
          }}
        />
        <Legend
          formatter={customLegendFormatter}
          iconType="circle"
          iconSize={10}
        />
        <Line
          type="monotone"
          dataKey={BarKey}
          stroke="#82ca9d"
          label={legend}
        />
        {compare && (
          <Line
            type="monotone"
            dataKey={CompareKey}
            stroke="#8884d8"
            activeDot={{ r: 8 }}
            label={legendCompare}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};
