import React from "react";
import classNames from "classnames";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";

type DateInputProps = {
  value: string;
  onChange: (date: Date) => any;
  disabled?: boolean;
};

export const DateInput: React.FC<DateInputProps> = ({
  value,
  onChange,
  disabled = false,
}) => {
  const [date, setDate] = React.useState(() => {
    const d = value
      ? new TZDate(new Date(value).toISOString(), TIMEZONES.AR)
      : new TZDate(new Date().toISOString(), TIMEZONES.AR);
    return {
      day: d.getDate(),
      month: d.getMonth() + 1,
      year: d.getFullYear(),
    };
  });
  const monthRef = React.useRef<any>(null);
  const dayRef = React.useRef<any>(null);
  const yearRef = React.useRef<any>(null);

  React.useEffect(() => {
    const d = value
      ? new TZDate(new Date(value).toISOString(), TIMEZONES.AR)
      : new TZDate(new Date().toISOString(), TIMEZONES.AR);
    setDate({
      day: d.getDate(),
      month: d.getMonth() + 1,
      year: d.getFullYear(),
    });
  }, [value]);

  const validateDate = (field: string, value: number) => {
    if (
      (field === "day" && (value < 1 || value > 31)) ||
      (field === "month" && (value < 1 || value > 12)) ||
      (field === "year" && (value < 1000 || value > 9999))
    ) {
      return false;
    }

    // Validate the day of the month
    const newDate = { ...date, [field]: value };
    const d = new TZDate(
      new Date(newDate.year, newDate.month - 1, newDate.day).toISOString(),
      TIMEZONES.AR
    );
    return (
      d.getFullYear() === newDate.year &&
      d.getMonth() + 1 === newDate.month &&
      d.getDate() === newDate.day
    );
  };

  const handleInputChange = (field: string) => (e: any) => {
    const newValue = e.target.value ? Number(e.target.value) : "";
    const isValid =
      typeof newValue === "number" && validateDate(field, newValue);

    // If the new value is valid, update the date
    const newDate = { ...date, [field]: newValue };
    setDate(newDate);

    // only call onChange when the entry is valid
    if (isValid) {
      onChange(
        new TZDate(
          new Date(newDate.year, newDate.month - 1, newDate.day).toISOString(),
          TIMEZONES.AR
        )
      );
    }
  };

  const initialDate = React.useRef(date);

  const handleBlur = (field: string) => (e: any) => {
    if (!e.target.value) {
      setDate(initialDate.current);
      return;
    }

    const newValue = Number(e.target.value);
    const isValid = validateDate(field, newValue);

    if (!isValid) {
      setDate(initialDate.current);
    } else {
      // If the new value is valid, update the initial value
      initialDate.current = { ...date, [field]: newValue };
    }
  };

  const handleKeyDown = (field: string) => (e: any) => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      let newDate = { ...date };

      if (field === "day") {
        if (date[field] === new Date(date.year, date.month, 0).getDate()) {
          newDate = { ...newDate, day: 1, month: (date.month % 12) + 1 };
          if (newDate.month === 1) newDate.year += 1;
        } else {
          newDate.day += 1;
        }
      }

      if (field === "month") {
        if (date[field] === 12) {
          newDate = { ...newDate, month: 1, year: date.year + 1 };
        } else {
          newDate.month += 1;
        }
      }

      if (field === "year") {
        newDate.year += 1;
      }

      setDate(newDate);
      onChange(new Date(newDate.year, newDate.month - 1, newDate.day));
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      let newDate = { ...date };

      if (field === "day") {
        if (date[field] === 1) {
          newDate.month -= 1;
          if (newDate.month === 0) {
            newDate.month = 12;
            newDate.year -= 1;
          }
          newDate.day = new Date(newDate.year, newDate.month, 0).getDate();
        } else {
          newDate.day -= 1;
        }
      }

      if (field === "month") {
        if (date[field] === 1) {
          newDate = { ...newDate, month: 12, year: date.year - 1 };
        } else {
          newDate.month -= 1;
        }
      }

      if (field === "year") {
        newDate.year -= 1;
      }

      setDate(newDate);
      onChange(
        new TZDate(
          new Date(newDate.year, newDate.month - 1, newDate.day).toISOString(),
          TIMEZONES.AR
        )
      );
    }

    if (e.key === "ArrowRight") {
      if (
        e.currentTarget.selectionStart === e.currentTarget.value.length ||
        (e.currentTarget.selectionStart === 0 &&
          e.currentTarget.selectionEnd === e.currentTarget.value.length)
      ) {
        e.preventDefault();
        if (field === "month") dayRef.current?.focus();
        if (field === "day") yearRef.current?.focus();
      }
    } else if (e.key === "ArrowLeft") {
      if (
        e.currentTarget.selectionStart === 0 ||
        (e.currentTarget.selectionStart === 0 &&
          e.currentTarget.selectionEnd === e.currentTarget.value.length)
      ) {
        e.preventDefault();
        if (field === "day") monthRef.current?.focus();
        if (field === "year") dayRef.current?.focus();
      }
    }
  };
  const stylesContainer = classNames(
    "flex border rounded-md items-center text-xs px-1 w-full",
    disabled && "pointer-events-none text-slate-400"
  );

  return (
    <div className={stylesContainer}>
      <input
        type="text"
        ref={dayRef}
        max={31}
        maxLength={2}
        value={date.day.toString()}
        onChange={handleInputChange("day")}
        onKeyDown={handleKeyDown("day")}
        onFocus={(e) => e.target.select()}
        onBlur={handleBlur("day")}
        className="p-0 outline-none w-7 border-none text-center text-xs"
        placeholder="D"
        disabled={disabled}
      />
      <span>/</span>
      <input
        type="text"
        ref={monthRef}
        max={12}
        maxLength={2}
        value={date.month.toString()}
        onChange={handleInputChange("month")}
        onKeyDown={handleKeyDown("month")}
        onFocus={(e) => {
          e.target.select();
        }}
        onBlur={handleBlur("month")}
        className="p-0 outline-none w-6 border-none text-center text-xs"
        placeholder="M"
        disabled={disabled}
      />
      <span>/</span>
      <input
        type="text"
        ref={yearRef}
        max={9999}
        maxLength={4}
        value={date.year.toString()}
        onChange={handleInputChange("year")}
        onKeyDown={handleKeyDown("year")}
        onFocus={(e) => e.target.select()}
        onBlur={handleBlur("year")}
        className="p-1 outline-none w-12 border-none text-center text-xs"
        placeholder="YYYY"
        disabled={disabled}
      />
    </div>
  );
};

DateInput.displayName = "DateInput";
