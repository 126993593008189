import { getDay } from "date-fns";
import { TZDate } from "@date-fns/tz";

// Dejo esto aca para hacerlo escalable.
// Lista de timezones: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
export const TIMEZONES = {
  AR: "America/Buenos_Aires",
};

export const INITIAL_PARAMS_SHEDULE = {
  date: TZDate.tz(TIMEZONES.AR),
  id_specialty: -1,
  id_professional: null,
  id_prof_finally: -1,
  id_office: null,
  option: "buscar",
  id_layout: 1,
  filter_professional_by_day: false,
  day_of_week: getDay(new Date()) === 0 ? 7 : getDay(new Date()),
};

export const INITIAL_PARAMS_CONFIG = {
  type_table: "expanded",
  open_trigger: true,
  extend_schedule: false,
  view_more: false,
};

export const LAYOUTS: {
  id_layout: number;
  description: string;
  value: string;
}[] = [
  { id_layout: 1, description: "Diaria", value: "buscar" },
  {
    id_layout: 2,
    description: "Por profesionales",
    value: "buscarpordiatodosprof",
  },
  { id_layout: 3, description: "Semanal", value: "buscarsemana" },
];

export const DAYS = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"];

export const FILTER_OPTIONS = [
  {
    value: "ATE",
    label: "Atendido",
  },
  {
    value: "ASA",
    label: "Ausente sin aviso",
  },
  {
    value: "PRE",
    label: "Presente",
  },
  {
    value: "ACA",
    label: "Ausente con aviso",
  },
  {
    value: "CAN",
    label: "Cancelado",
  },
  {
    value: "CON",
    label: "Confirmado",
  },
  {
    value: null,
    label: "Sin confirmar",
  },
];

export const ATTENDANCE_OPTIONS = [
  {
    text: "Presente",
    id: "PRE",
    styles: "bg-lime-200 text-lime-800 border-lime-800",
  },
  {
    text: "Atendido",
    id: "ATE",
    styles: "bg-blue-200 text-blue-800 border-blue-800",
  },
  {
    text: "Ausente con aviso",
    id: "ACA",
    styles: "!bg-amber-200 !text-amber-800 !border-amber-800",
  },
  {
    text: "Ausente sin aviso",
    id: "ASA",
    styles: "bg-red-200 text-red-800 border-red-800",
  },
  {
    text: "Confirmado",
    id: "CON",
    styles: "bg-emerald-200 text-emerald-800 border-emerald-800",
  },
  {
    text: "Cancelado",
    id: "CAN",
    styles: "bg-slate-200 text-slate-800 border-slate-800",
  },
  {
    text: "Sin confirmar",
    id: null,
    styles: "bg-neutral-100 text-neutral-500 border-neutral-500",
  },
  {
    text: "Limpiar asistencia",
    id: "DEL",
    styles: "",
  },
];

export const ATTENDANCE_OPTIONS_REMINDERS = [
  {
    text: "Ausente con aviso",
    id: "ACA",
    styles: "bg-amber-200 text-amber-800 border-amber-800",
  },

  {
    text: "Confirmado",
    id: "CON",
    styles: "bg-emerald-200 text-emerald-800 border-emerald-800",
  },

  {
    text: "Sin confirmar",
    id: null,
    styles: "bg-neutral-100 text-neutral-500 border-neutral-500",
  },
];

export const KEYS_PERMISSIONS = [
  { key: "viewContactData", tab: "Ver Datos" },
  { key: "viewPatientData", tab: "Ver Paciente" },
  { key: "giveOverturn", tab: "Sobreturno" },
  { key: "disableAppointment", tab: "Anular Turnos" },
  { key: "editObservation", tab: "Obs. del Turno" },
];

export const PATIENT_TYPES = [
  {
    id_patient_type: 1,
    value: "new_appointment_new_patient",
    text: "Paciente nuevo",
  },
  {
    id_patient_type: 2,
    value: "new_appointment_patient_exist",
    text: "Paciente existente",
  },
];

export const CRITERIA_OPTIONS = [
  {
    key: "professional",
    label: "Por profesional",
  },
  {
    key: "specialty",
    label: "Por especialidad",
  },
];
export const OFFICES_OPTIONS = [
  {
    key: "this_office",
    label: "En esta sucursal",
  },
  {
    key: "all_offices",
    label: "En todas las sucursales",
  },
];

export const GEIST_DATA: any[] = [
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
  {
    action: "",
    time: "",
    presentism: "",
    patient: "",
    observation: "",
    mod_by: "",
  },
] as const;
