"use client";

import { es } from "date-fns/locale";
import * as React from "react";
import "react-day-picker/style.css";
import { DayPicker } from "react-day-picker";
import clxs from "classnames";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  captionLayout,
  disabled,
  startMonth,
  endMonth,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      captionLayout={captionLayout}
      disabled={disabled}
      showOutsideDays={showOutsideDays}
      locale={es}
      className={clxs("pr-3", className)}
      startMonth={startMonth}
      endMonth={endMonth}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month_grid: "text-md",
        weekday: "text-sm text-slate-800 pt-2",
        chevron:
          "border border-slate-200 bg-white hover:bg-slate-100 hover:text-slate-900 size-8 p-2 rounded",
        selected:
          "bg-blue-600 text-white hover:bg-blue-600 focus:bg-blue-600 focus:text-slate-50 dark:bg-slate-50 dark:text-blue-600 dark:hover:bg-slate-50 dark:hover:text-blue-600 dark:focus:bg-slate-50 dark:focus:text-blue-600",
        day_today:
          "bg-slate-100 text-blue-500 dark:bg-slate-800 dark:text-slate-50",
        day: "size-9 p-0 text-sm aria-selected:opacity-100 hover:bg-blue-100 text-slate-600 rounded-md",
        range_middle:
          "aria-selected:bg-slate-100 !rounded-none aria-selected:text-blue-600 dark:aria-selected:bg-slate-800 dark:aria-selected:text-slate-50",
        disabled: "text-slate-500 opacity-50 dark:text-slate-400",
        range_end: "bg-blue-500",
        range_start: "bg-blue-500",
        months_dropdown: "!pl-1 !w-28",
        dropdowns: "flex gap-x-4",
        years_dropdown: "!pl-1 !w-24",
      }}
      {...props}
    />
  );
}

Calendar.displayName = "Calendar";

export { Calendar };
