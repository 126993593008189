import { Children } from "react";

/**
 * This component renders a children based in logic.
 *
 * @param {any} props The component props if needed.
 * @returns {ReactNode} A React child element.
 */
export const Show = (props: any) => {
  let when: null = null;
  let otherwise = null;

  Children.forEach(props.children, (child) => {
    if (child.props.isTrue === undefined) {
      otherwise = child;
    } else if (!when && child.props.isTrue === true) {
      when = child;
    }
  });

  return when || otherwise;
};

Show.When = ({
  isTrue,
  children,
}: {
  isTrue: boolean;
  children: React.ReactNode;
}) => isTrue && children;
Show.Else = ({
  render,
  children,
}: {
  render: () => void;
  children: React.ReactNode;
}) => render || children;
