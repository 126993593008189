import React from "react";

export const FormattedTooltip: React.FC<{
  name: string;
  type: string;
  payload?: any[];
  color: { original: string; comparison: string };
  compare: string;
}> = ({ name, type, payload, color, compare }) => {
  const periodEntries = payload?.filter((entry) => entry.name === "Principal");
  const formatDateGraphic = (dateString: string) => {
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return ` ${day.toString().padStart(2, "0")}-${month}-${year} `;
  };

  return (
    <div className="bg-white p-2 border border-slate-200">
      {periodEntries?.map((periodEntry, index) => (
        <div key={index}>
          {!periodEntry.payload?.label ? (
            <h4>
              {formatDateGraphic(periodEntry.payload?.period)}
              {compare &&
                `vs
              ${formatDateGraphic(
                periodEntries[index]?.payload?.comparation_period
              )}`}
            </h4>
          ) : (
            <h4 className="text-sm">
              {periodEntry.payload?.label}
              {name === "Rentabilidad de paciente por pago en caja" &&
                compare &&
                ` vs ${periodEntry.payload?.comparation_label}`}
            </h4>
          )}
          <div style={{ color: color.original }}>
            {`${compare ? "Principal" : "Valor"}: ${periodEntry.value}`}
          </div>
          {compare && (
            <div style={{ color: color.comparison }}>
              {`Comparacion: ${periodEntries[index]?.payload?.comparation_value}`}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
